<template>
  <div class="saleSend">
    <h1>销售明细（发车）</h1>
    <el-form :inline="true">
      <el-form-item label="发货日期">
        <el-date-picker v-model="searchParam.sendDates" type="daterange" start-placeholder="起" end-placeholder="止" style="width: 220px;" />
      </el-form-item>
      <el-form-item label="仓储">
        <el-select v-model="searchParam.storage" filterable style="width: 120px;" clearable>
          <el-option v-for='storageItem in show.storageList' :key="storageItem.id" :label="storageItem.value" :value="storageItem.value" />
        </el-select>
      </el-form-item>
      <el-form-item label="车号">
        <el-autocomplete v-model="searchParam.truckNumber" style="width: 120px;" :fetch-suggestions="getTruckNumbers" />
      </el-form-item>
      <el-form-item>
        <el-button-group>
          <el-button type="primary" @click="commitFindList()">搜索</el-button>
          <el-button @click="resetSearchParam()">重置</el-button>
        </el-button-group>
      </el-form-item>
      <el-form-item>
        <el-button-group>
          <el-button type="warning" :disabled="!(show.selected.length == 1)" @click="update()">详情</el-button>
        </el-button-group>
      </el-form-item>
    </el-form>
    <el-table :data="data.list" @selection-change="selectionChange()" ref="tabRef" show-summary :summary-method="summary" border style="height: calc(100vh - 310px);">
      <el-table-column type="selection" width="55" align="center" />
      <el-table-column width="100" align="center">
        <template #default="scope">
          <el-link type="primary" @click="update(scope.row)">{{ scope.row.id }}</el-link>
        </template>
      </el-table-column>
      <el-table-column prop="sendDate" label="发货日期" width="120" show-overflow-tooltip />
      <el-table-column prop="truckNumber" label="车号" width="120" show-overflow-tooltip />
      <el-table-column prop="storage" label="仓储" width="120" show-overflow-tooltip />
      <el-table-column prop="sendQuantity" label="发货数量" width="120" show-overflow-tooltip align="right">
        <template #default="scope">
          {{ math.formatNumber(scope.row.sendQuantity) }}
        </template>
      </el-table-column>
      <el-table-column prop="note" label="备注信息" show-overflow-tooltip />
    </el-table>
    <div style="height: 10px;" />
    <el-pagination v-model:current-page="searchParam.pageNum" v-model:page-size="searchParam.pageSize" :page-sizes="[50, 100, 200]" background layout="total, sizes, prev, pager, next, jumper" :total="data.total" @change="commitFindList()" />
  </div>
</template>

<script setup>
import { reactive, ref } from 'vue'
import { useRouter } from 'vue-router'
import { ElMessage, ElMessageBox } from 'element-plus'
import api from '../../apis/api'
import apiNative from '../../apis/apiNative'
import cache from '../../utils/cache'
import util from '../../utils/util'
import math from '../../utils/math'

const router = useRouter()
const tabRef = ref()

const data = reactive({
  total: 0,
  list: [],
  accumulate: {},
})

const show = reactive({
  selected: [],
  customList: [],
  storageList: [],
})

api.get('/backend/dict/getByCode', { params: { code: 'custom' } }).then(res => {
  show.customList = res.dictList
})
api.get('/backend/dict/getByCode', { params: { code: 'storage' } }).then(res => {
  show.storageList = res.dictList
})

const now = new Date()
const yesterday = new Date(now.getTime() - 1 * 24 * 60 * 60 * 1000)


const searchParam = reactive({})
const resetSearchParam = () => {
  searchParam.pageNum = 1
  searchParam.pageSize = 50
  searchParam.sendDates = [yesterday, now]
  searchParam.hasReceive = null
  searchParam.custom = null
  searchParam.storage = null
  searchParam.truckNumber = null
}
resetSearchParam()
const beforeSearchParam = cache.getObject(cache.keys.pageParam + 'saleSend')
if (beforeSearchParam) {
  Object.assign(searchParam, beforeSearchParam)
  cache.setObject(cache.keys.pageParam + 'saleSend', null)
}

const getTruckNumbers = (queryString, callback) => {
  api.get('/backend/sale/getTruckNumbers', { params: { truckNumberLike: queryString } }).then(res => {
    callback(res.list.map(item => { return { value: item } }))
  })
}

const getPageParam = () => {
  const params = {}
  params.orderBy = 'sendDate_desc_id_desc'
  params.pageNum = searchParam.pageNum
  params.pageSize = searchParam.pageSize
  if (searchParam.sendDates && searchParam.sendDates.length == 2) {
    params.sendDateGe = util.parseTime(searchParam.sendDates[0], '{y}-{m}-{d}')
    params.sendDateLe = util.parseTime(searchParam.sendDates[1], '{y}-{m}-{d}')
  }
  params.hasReceive = searchParam.hasReceive
  params.custom = searchParam.custom
  params.storage = searchParam.storage
  params.truckNumber = searchParam.truckNumber
  return params
}

const commitFindList = () => {
  api.get('/backend/sale/page', { params: getPageParam() }).then(res => {
    data.list = res.list
    data.total = res.total
    data.accumulate = res.data
  })
}
commitFindList()

const summary = () => {
  return ['合计', '', '', math.formatNumber(data.accumulate.count), '', '', '', math.formatNumber(data.accumulate.sendQuantity)]
}

const selectionChange = () => {
  show.selected = tabRef.value.getSelectionRows()
}

const add = () => {
  cache.setObject(cache.keys.pageParam + 'saleSend', searchParam)
  cache.setObject(cache.keys.pageParam + 'saleSendDetail', null)
  router.push('/saleSendDetail')
}

const update = (row) => {
  cache.setObject(cache.keys.pageParam + 'saleSend', searchParam)
  const sale = row ? row : tabRef.value.getSelectionRows()[0]
  cache.setObject(cache.keys.pageParam + 'saleSendDetail', sale)
  router.push('/saleSendDetail')
}

const del = () => {
  const ids = tabRef.value.getSelectionRows().map(sale => sale.id)
  ElMessageBox.confirm('', {
    title: '确认删除',
    message: '将要删除选中的 ' + ids.length + ' 项。',
    autofocus: false,
    confirmButtonText: '确认',
    cancelButtonText: '取消'
  }).then(() => {
    api.post('/backend/sale/del', { ids: ids }).then(() => {
      ElMessage.success('删除成功')
      commitFindList()
    })
  })
}

const exportExcel = () => {
  ElMessageBox.confirm('', {
    title: '确认导出',
    message: '将要按照当前选择的查询条件进行导出。',
    autofocus: false,
    confirmButtonText: '确认',
    cancelButtonText: '取消'
  }).then(() => {
    ElMessage.success('正在导出！如果数据量大可能时间较长，请耐心等待。')
    apiNative.get('/backend/sale/exportExcelSend', {
      params: getPageParam(),
      responseType: 'blob'
    }).then(res => {
      searchParam.exportShow = false
      ElMessage.success('开始下载')
      util.downloadFile(res, '销售明细（发车）' + util.parseTime(new Date(), '{y}{m}{d}{h}{i}{s}'), 'xlsx')
    }).catch(() => {
      ElMessage.error('导出失败，请联系系统管理员')
    })
  })
}
</script>

<style lang="less"></style>